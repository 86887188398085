<template>
    <canvas id="viewer" ref="viewer" :width="width" :height="height"/>
</template>

<script>

export default {
    name: "ViewComponent",
    props: ['width', 'height', 'component'],
    data() {
        return {}
    },
    mounted() {
        if (this.component && ('loops' in this.component)) {
            this.drawComponent();
        }
    },
    methods: {
        drawComponent() {
            function scaleX(x) {
                return margin + parseFloat(x) * scaleFactor + offsetX;
            }

            function scaleY(y) {
                return margin + canvasHeight - (parseFloat(y) * scaleFactor + offsetY);
            }

            function circle(center, radius, color = standardColor) {
                ctx.beginPath();
                ctx.arc(scaleX(center.x), scaleY(center.y), parseFloat(radius) * scaleFactor, 0, 2 * Math.PI);
                ctx.strokeStyle = color;
                ctx.lineWidth = standardLineWidth;
                ctx.stroke();
            }

            function line(from, to, lineWidth = standardLineWidth, color = standardColor) {
                ctx.beginPath();
                ctx.moveTo(scaleX(from.x), scaleY(from.y));
                ctx.lineTo(scaleX(to.x), scaleY(to.y));
                ctx.strokeStyle = color;
                ctx.lineWidth = lineWidth;
                ctx.stroke();
            }

            function max(max, value) {
                return (max == null || max < value) ? value : max;
            }

            function min(min, value) {
                return (min == null || min > value) ? value : min;
            }

            const margin = 20;
            const foldColor = "Red";
            const foldLineWidth = 1;
            const standardColor = "DarkGray";
            const standardLineWidth = 1;
            const allowanceColor = "LimeGreen";
            const allowanceLineWidth = 5;
            const deductionColor = "LimeGreen"
            const deductionRadius = 10;

            const canvas = this.$refs['viewer'];
            const ctx = canvas.getContext("2d");
            const canvasWidth = ctx.canvas.width - 2 * margin;
            const canvasHeight = ctx.canvas.height - 2 * margin;

            let minX = null, minY = null, maxX = null, maxY = null;
            this.component.loops.forEach(l => {
                if (l.circle) {
                    minX = min(minX, parseFloat(l.arccurve.x) - parseFloat(l.arccurve.radius));
                    minY = min(minY, parseFloat(l.arccurve.y) - parseFloat(l.arccurve.radius));
                    maxX = max(maxX, parseFloat(l.arccurve.x) + parseFloat(l.arccurve.radius));
                    maxY = max(maxY, parseFloat(l.arccurve.y) + parseFloat(l.arccurve.radius));
                } else {
                    l.vertices.forEach(v => {
                        if (v.allowance_deduction < 0) {
                            minX = min(minX, parseFloat(v.x) - 8);
                            minY = min(minY, parseFloat(v.y) - 8);
                            maxX = max(maxX, parseFloat(v.x) + 8);
                            maxY = max(maxY, parseFloat(v.y) + 8);
                        }
                        minX = min(minX, parseFloat(v.x));
                        minY = min(minY, parseFloat(v.y));
                        maxX = max(maxX, parseFloat(v.x));
                        maxY = max(maxY, parseFloat(v.y));
                    });
                }
            });
            const componentWidth = maxX - minX;
            const componentHeight = maxY - minY;
            let scaleFactor;
            let offsetX, offsetY;
            if (canvasWidth / componentWidth > canvasHeight / componentHeight) {
                scaleFactor = canvasHeight / componentHeight;
                offsetX = -minX * scaleFactor + (canvasWidth - componentWidth * scaleFactor) / 2;
                offsetY = -minY * scaleFactor;
            } else {
                scaleFactor = canvasWidth / componentWidth;
                offsetX = -minX * scaleFactor;
                offsetY = -minY * scaleFactor + (canvasHeight - componentHeight * scaleFactor) / 2;
            }

            this.component.loops.forEach(l => {
                if (l.circle) {
                    circle(l.arccurve, l.arccurve.radius);
                } else {
                    let from = null;
                    l.vertices.forEach(v => {
                        if (v.allowance_deduction < 0) {
                            circle(v, deductionRadius / scaleFactor, deductionColor);
                        }
                        if (from) {
                            if (from.allowance_deduction > 0 || v.allowance_deduction > 0) {
                                line(from, v, allowanceLineWidth, allowanceColor);
                            } else {
                                line(from, v);
                            }
                        }
                        from = v;
                    });
                    if (from.allowance_deduction > 0 || l.vertices[0].allowance_deduction > 0) {
                        line(from, l.vertices[0], allowanceLineWidth, allowanceColor);
                    } else {
                        line(from, l.vertices[0]);
                    }
                }
            });

            if ('folds' in this.component) {
                this.component.folds.forEach(f => {
                    f.markers.forEach(m => {
                        line({x: m.x1, y: m.y1}, {x: m.x2, y: m.y2}, foldLineWidth, foldColor);
                    })
                });
            }
        },
    }
}
</script>

<style scoped>
#viewer {
    outline: 1px solid #DFDFDF;
}
</style>
