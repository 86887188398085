import { render, staticRenderFns } from "./PunchPlates.vue?vue&type=template&id=ec8839ee&scoped=true&"
import script from "./PunchPlates.vue?vue&type=script&lang=js&"
export * from "./PunchPlates.vue?vue&type=script&lang=js&"
import style0 from "./PunchPlates.vue?vue&type=style&index=0&id=ec8839ee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec8839ee",
  null
  
)

export default component.exports